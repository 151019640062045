<template>
    <div class="card-etapa-config border-dd br-4 cr-pointer pt-3 px-3">
        <div class="row">
            <p class="col-12 tres-puntos f-600 ucfirst"> {{ name }} </p>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="d-middle">
                    <i class="icon-format-list-bulleted-type f-20" />
                    <p class="f-14"> {{ cantItems }} - {{ formatoMoneda(valueItems) }} </p>
                </div>
            </div>
            <div class="col-6">
                <div class="d-middle">
                    <i class="icon-account-clock f-20" />
                    <p class="f-14"> {{ cantHours }} Hrs - {{ formatoMoneda(valueHours) }} </p>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="d-middle">
                    <p class="f-16 f-500"> Porcentaje de utilidad {{ percentage }}% </p>
                </div>
            </div>
        </div>
        <div class="row info-bottom mt-3 py-2 border-top">
            <div class="col-12 text-right f-18 f-500">
                <p> {{ formatoMoneda(valueItems + valueHours) }} </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        name:{
            type: String,
            required: true
        },
        cantItems:{
            type: Number,
        },
        valueItems:{
            type: Number,
        },
        cantHours:{
            type: Number,
        },
        valueHours:{
            type: Number,
        },
        percentage: {
            type: [String, Number],
            default: 0
        }
    }

}
</script>
<style lang="scss" scoped>
.card-etapa-config{
    background: var(--color-f9);
    i::before{
        margin-left: 0px;
    }
    .info-bottom{
        background: #F1F2F4;
    }
}
</style>
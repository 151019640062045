<template>
    <section class="almacen-etapas-ver">
        <navbar-admin titulo="Alamacén > Etapa 1">
            <!-- edit stage -->
            <el-popover v-model="visibleEditarEtapa" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapas.renombrar')">
                <div class="row mx-0 justify-content-center f-600 text-general">
                    Cambiar de nombre
                </div>
                <hr />
                <ValidationObserver ref="validator">
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                                <p class="input-label-top">Nombre de la etapa</p>
                                <el-input v-model="model.nombre" class="f-12 br-5 w-100" size="small" />
                                <vee-error :text="errors[0]"/>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
                <hr />
                <div class="text-right m-0">
                    <button class="btn btn-cerrar f-12" @click="visibleEditarEtapa = !visibleEditarEtapa">Cerrar</button>
                    <button class="btn btn-crear f-12 ml-2" @click="editStage">Guardar</button>
                </div>
                <el-tooltip slot="reference" content="Cambiar nombre" placement="bottom-end" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d ml-2"
                        :class="permitAction('almacen.etapas.renombrar') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.etapas.renombrar')"
                    >
                        <i class="icon-pencil-outline text-white"/>
                    </button>
                </el-tooltip>
            </el-popover>
            <!-- delete stage -->
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2 cr-pointer" 
                    :class="permitAction('almacen.etapas.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.etapas.eliminar', eliminarEtapa)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <!-- nombre etapa -->
        <div class="row mx-0">
            <div class="col-10">
                <div class="etapa-titulo d-flex border-dd br-4">
                    <div class="color" />
                    <div class="row mx-0 w-100">
                        <div class="col my-auto">
                            <p class="f-600 pl-3 ucfirst tres-puntos"> {{ stage.nombre }} </p>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <el-tooltip content="Número de configuraciones" effect="light" visible-arrow>
                                <div class="d-flex">
                                    <i class="icon-format-list-bulleted-type f-18 mr-1" />
                                    <p class="my-auto f-14"> {{ stage.configuraciones }} </p>
                                </div>
                            </el-tooltip>
                        </div>
                        <!--create configuration -->
                        <div class="col-auto my-auto">
                            <el-popover v-model="visibleCrearConfig" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapas.crear.configuracion')">
                                <div class="row mx-0 justify-content-center f-600 text-general">
                                    Crear configuración
                                </div>
                                <hr />
                                <ValidationObserver ref="validatorConfiguracion">
                                    <div class="row mx-0 justify-content-center my-2">
                                        <div class="col-10 my-2">
                                            <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                                                <p class="input-label-top">Nombre de la configuración</p>
                                                <el-input v-model="modelConfiguration.nombre" class="f-12 br-5 w-100" size="small" />
                                                <vee-error :text="errors[0]"/>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-10 my-2">
                                            <ValidationProvider v-slot="{ errors }" name="horas" rules="required|min_value:0">
                                                <p class="input-label-top">Horas / Hombre</p>
                                                <el-input v-model="modelConfiguration.horas" class="f-12 br-5 w-100" size="small" />
                                                <vee-error :text="errors[0]"/>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-10 my-2">
                                            <ValidationProvider v-slot="{ errors }" name="utilidad" rules="required|min_value:0|max_value:100">
                                                <p class="input-label-top">Porcentaje de utilidad</p>
                                                <el-input v-model="modelConfiguration.porcentaje" class="f-12 br-5 w-100" size="small" />
                                                <vee-error :text="errors[0]"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>
                                <hr />
                                <div class="text-right m-0">
                                    <button class="btn btn-cerrar f-12" @click="visibleCrearConfig = !visibleCrearConfig">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="createConfiguration">Crear</button>
                                </div>
                                <el-tooltip slot="reference" content="Crear configuración" placement="bottom" effect="light" visible-arrow>
                                    <div class="icon-option">
                                        <i class="icon-plus-circle f-30 cr-pointer" 
                                            :class="permitAction('almacen.etapas.crear.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                                            @click="functionPermitAction('almacen.etapas.crear.configuracion')"
                                        />
                                    </div>
                                </el-tooltip>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- listado  -->
        <div class="row mx-0 mt-3">
            <div class="col-10">
                <div class="row">
                    <div v-for="data in stageConfiguration" :key="data.id" class="col-6 mb-3" @click="verConfiguracion(data.id)">
                        <card-phase-configuration
                            :name="data.nombre"
                            :cantItems="data.materiales"
                            :valueItems="data.valor_material"
                            :cantHours="data.horas"
                            :valueHours="data.valor_horas"
                            :percentage="data.porcentaje"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- partials  -->
        <modal-eliminar ref="abrirModalEliminarEtapa" title="Eliminar etapa" mensaje="¿Está seguro que quiere eliminar esta etapa?" mensaje2="Se eliminará de cualquier equipo modelo que lo tenga en su sistema" @delete="deleteStage" />
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import cardPhaseConfiguration from '../components/cardPhaseConfiguration.vue'
export default {
    components:{
        cardPhaseConfiguration,
    },
    data(){
        return{
            visibleEditarEtapa: false,
            visibleCrearConfig: false,
            visible: false,
            cambiarNombre: '',
            inputNombreConfig: '',
            inputHorasHombre: '',
            model: {
                id: null,
                nombre:'',
            },
            modelConfiguration:{
                nombre: '',
                horas: '',
                porcentaje: '',
            },
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            stage: 'almacen/stages/stage',
            stageConfiguration: 'almacen/stages/stagesConfigurations',
        }),
        idStage(){
            return this.$route.params.id
        }
    },
    async created(){
        // set Breadcumbs
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['almacen.main','almacen.etapas']);
        // get data from stage            
        await this.setDataStage()
        // get data of stageConfigurations
        this.setDataStageConfiguration()

        this.model = {
            id: this.stage.id,
            nombre: this.stage.nombre,
        }
    },
    watch: {
        stage: {
            handler(val) {
                this.removeBreadcumb(2)
                this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs', [
                    {
                        position: 2,
                        breadcumb: {
                            name: val.nombre,
                            route: 'almacen.etapas.ver',
                            params: { id: this.stage.id }
                        }
                    }
                ])
            },
            deep: true
        }
    },
    methods:{
        ...mapActions({
            removeBreadcumb: 'navigation/breadcumbs/removeBreadcumb'
        }),
        async setDataStage(){
            await this.$store.dispatch('almacen/stages/Action_get_stage', this.idStage);
        },
        async setDataStageConfiguration(){
            let obj = {
                id_etapa: this.idStage
            }
            await this.$store.dispatch('almacen/stages/Action_get_configurations', obj);
        },
        verConfiguracion(id){
            this.$router.push({ name: 'almacen.etapas.ver.configuracion' , params: { id: this.idStage, id_configuracion: id } })
        },
        async editStage(){
            let valid = await this.$refs.validator.validate();
            if (valid) {
                await this.$store.dispatch('almacen/stages/Action_update_stage', this.model )
            }
            this.visibleEditarEtapa = !this.visibleEditarEtapa
        },
        eliminarEtapa(){
            this.$refs.abrirModalEliminarEtapa.toggle()
        },
        async deleteStage(){
            await this.$store.dispatch('almacen/stages/Action_delete_stage', this.idStage)
            this.$router.push({ name: 'almacen.etapas' })

        },
        async createConfiguration(){
            let valid = await this.$refs.validatorConfiguracion.validate();
            if (valid) {
                // create configuration
                let obj = {
                    ...this.modelConfiguration,
                    id: this.idStage
                }
                const { id } = await this.$store.dispatch('almacen/stages/Action_create_configuration', obj)
                //redirect to configuration
                this.$router.push({ name: 'almacen.etapas.ver.configuracion', params: { id: this.idStage, id_configuracion: id } })
                // close popover
                this.visibleCrearConfig = !this.visibleCrearConfig
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.almacen-etapas-ver{
    .etapa-titulo{
        background: var(--color-f9);
        .color{
            background: var(--color-general);
            border-radius: 4px 0px 0px 4px;
            height: 52px;
            width: 9px;
        }
    }
    .card-etapa-config{
        background: var(--color-f9);
        i::before{
            margin-left: 0px;
        }
        .info-bottom{
            background: #F1F2F4;
        }
    }
}
</style>